import React, { Component } from 'react';
import { NavLink ,withRouter } from 'react-router-dom';
import AppState from '../../../contexts/AppState/AppState';
import AppProvider from '../../../contexts/AppState/AppStateProvider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSortUp, faSortDown, faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import List from '../../common/List/List';
import API from '../../../services/API';
import ViewFudingRequestModal from './ViewFudingRequestModal';
import ViewMatchedFunderModal from './ViewMatchedFunderModal';
import MakeOfferModal from './MakeOfferModal';
import ViewSupplierFundingRequestModal from './ViewSupplierFundingRequestModal';
import ViewFundingOfferModal from './ViewFundingOfferModal';
import FundingAgreementModal from './FundingAgreementModal';
import CommonConfirmationModal,{AddModal} from '../../common/Modals/CommonConfirmationModal';
import MessageModal from '../../common/Modals/MessageModal';
import User from '../../../services/User';
import { DocumentProductNavTabs } from '../../common/Navigation/NavigationTabs';
import DataGrid from '../../common/DataGrid/DataGrid';
import DataGridFilter from '../../common/DataGrid/DataGridFilter';
import { dollarFormatter, dateFormatter,getStepForFRTimeline ,addDaysInSufixForPaymentTerm} from '../../../services/Utilities';
import SignedAgreementModal from './SignedAgreementModal';
import ViewInvoiceModal from './../Documents/Invoices/ViewInvoiceModal';
import { POModal } from '../Documents/Po/POModal';
import { event, get } from 'jquery';
import FullSpinner from '../../common/Spinner/FullSpinner';
import FileAttachmentModal from '../../common/Modals/FileAttachmentModal';
import UploadSupplyChainModal from "../Documents/SupplyChain/UploadSupplyChainModal";
import { compareRole, compareTransactionType, enumRole, transactionType } from '../../../configs/GlobalConfig';
import NotificationService from '../../../services/NotificationService';
import EnumFundingRequestStatus from './EnumFundingRequestStatus';
import ApproveFundingRequestModal from './ApproveFundingRequestModal';
import PreFundingRequestModal from '../../common/Modals/PreFundingRequestModal';
import { isUSCountryUser } from '../../../services/Utilities';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { generateSteps, calculateStatusCounts, StatusStepper } from '../../../services/StatusStepperService';import { ExpandMore, ExpandLess } from '@mui/icons-material';
import Stepper from "react-stepper-horizontal";
import CheckmarkIcon from "../../../assets/icons/checkmark.png";
import CloseIcon from "../../../assets/icons/closeicon.png";
import { InputAdornment, TextField, Paper, TableContainer, Table, TableBody, TableRow, TableCell, Checkbox, IconButton, Grid, TablePagination, TableHead, TableSortLabel, Popover, Box, Typography, Button } from "@mui/material";


class FundingRequest extends Component {
    api = new API();
    user = new User();
    _isLive = false;

    _columnsSpecification = [
        {
            key: "funderName",
            name: this.props.t("grid_view_header_labels.funder_name"),
            contentProvider: (datum_) => {
                if (datum_.funderName) {
                    return datum_.funderName;
                }

                return <i>{this.props.t("none")}</i>;
            }
        },
        {
            key: "fundingReqId",
            name: this.props.t("grid_view_header_labels.fr_id"),
        },
        {
            key: "totalfundingAmount",
            name: this.props.t("grid_view_header_labels.collateral_amount"),
            textAlignment: "right",
            contentProvider: (datum_) => {
                return <div style={{ color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.totalfundingAmount)}</div>;
            }
        },
        {
            key: "fundingDocuments",
            name: this.props.t("grid_view_header_labels.funding_transactions"),
            contentProvider: (datum_) => {
                return <div style={{ color: "#255F79" }}>
                    {datum_.fundingType == "TCF" && (
                        <span>{this.props.t("fundingType.taxcredit")} ({datum_.fundingDocuments.length}) </span>
                    )}
                    {datum_.fundingType == "Invoice" && (
                        <span>{this.props.t("fundingType.invoice")} ({datum_.isPreFundingRequest?<span className="progress-text-color">{this.props.t("fundingType.prefundingrequest")}</span>: datum_.fundingDocuments.length}) </span>
                    )}
                    {datum_.fundingType == "PO" && (
                        <span>{this.props.t("fundingType.po")} ({datum_.isPreFundingRequest?<span className="progress-text-color">{this.props.t("fundingType.prefundingrequest")}</span>: datum_.fundingDocuments.length}) </span>
                    )}
                    {datum_.fundingType == "ABL" && (
                        <span>{this.props.t("fundingType.abl")}</span>
                    )}
                    {datum_.fundingType == "SupplyChain" && (
                         <span>{this.props.t("fundingType.supplychain")} ({datum_.isPreFundingRequest?<span className="progress-text-color">{this.props.t("fundingType.prefundingrequest")}</span>: datum_.fundingDocuments.length}) </span>
                    )}
                    </div>;
                    
            }
        },
        {
            key: "createdAt",
            name: this.props.t("grid_view_header_labels.requested_date"),
            contentProvider: (datum_) => {
                return dateFormatter.format(new Date(datum_.createdAt));
            }
        },
        {
            key: "statusText",
            name: this.props.t("grid_view_header_labels.status"),
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div style={{ color: "#4F4F4F", fontWeight: "600" }}>{datum_.statusText}</div>;
                    case 1:
                        return <div style={{ color: "#FFB45B" }}>{datum_.statusText}</div>;
                    case 2:
                        return <div style={{ color: "#46B2E3" }}>{datum_.statusText}</div>;
                    case 3:
                        return <div style={{ color: "#FFB45B" }}>{datum_.statusText}</div>;
                    case 4:
                        return <div style={{ color: "#4F984F" }}>{datum_.statusText}</div>;
                    case 5:
                        return <div style={{ color: "#930000" }}>{datum_.statusText}</div>;
                    case 6:
                        return <div style={{ color: "#46B2E3" }}>
                            {datum_.statusText}
                            {(datum_.contracts && datum_.contracts.length && !datum_.contracts[0].fundingAggrement) ? <div>({this.props.t("waiting_for_funding_agreement")})</div>
                                : ((datum_.contracts && datum_.contracts.length && !datum_.contracts[0].signedAggrements) ? <div>({this.props.t("waiting_for_signed_funding_agreement")})</div> : "")}
                        </div>;
                    case 7:
                        return <div style={{ color: "#4F984F" }}>{datum_.statusText}</div>;
                    case 11:
                        return <div style={{ color: "#46B2E3" }}>
                            {datum_.statusText}
                        </div>;
                    default:
                        return datum_.statusText;
                }
            }
        },
    ];


    _funderColumnSpecification = [
        {
            key: "supplierName",
            name: `${this.props.t("grid_view_header_labels.requester_name")}(${this.props.t("grid_view_header_labels.fr_id")})`,
            contentProvider: (datum_) => {

                if (datum_.requesterDetails &&  datum_.requesterDetails.name) {
                    return (
                        <div style={{ cursor: "pointer" }} onClick={(e) => {
                            this.setState({
                                isViewPopupOpen: true,
                                selectedTCF: datum_,
                            });
                        }}>
                            {datum_.isCreatedByRepresentive && (
                                <div style={{fontSize:12}}>
                                    <b> {datum_.createdUserDetails.adminName}</b>
                                    <br />
                                    on behalf of
                                    <br />
                                </div>
                            )}
                            <div style={{ color: "#255F79", fontWeight: 600 }}>{datum_.requesterDetails.name}
                                <span className="ml-2 badge badge-primary">
                                    {compareRole(datum_.requesterDetails.role, enumRole.Supplier) ? enumRole.Supplier : enumRole.Buyer}
                                </span>
                            </div>
                            <div className="data-grid-document cursor-pointer" style={{ cursor: "pointer" }} >{`(${datum_.fundingReqId})`}</div>
                        </div>);
                }

                return <i>{this.props.t("none")}</i>;
            }
        },
        {
            key: "totalfundingAmount",
            name: this.props.t("grid_view_header_labels.amount"),
            textAlignment: "right",
            contentProvider: (datum_) => {
                return <div style={{ color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.totalfundingAmount)}</div>;
            }
        },
        {
            key: "fundingDocuments",
            name: this.props.t("grid_view_header_labels.financing_transactions"),
            contentProvider: (datum_,key_,isExpand) => {
                return <div className={datum_.isPreFundingRequest?"":"cursor-pointer"} style={{ color: "#255F79" }}>
                    {datum_.fundingType == "TCF" && (
                        <span>{this.props.t("fundingType.taxcredit")} ({datum_.fundingDocuments.length}) </span>
                    )}
                    {datum_.fundingType == "Invoice" && (
                        <>
                            <span>{this.props.t("fundingType.invoice")}{datum_.isPreFundingRequest ? null : `(${datum_.fundingDocuments.length})`}</span>
                            {!datum_.isPreFundingRequest && (<>{isExpand ? <ExpandMore /> : <ExpandLess />}</>)}
                            {datum_.isPreFundingRequest ? <div className="progress-text-color">{`(${this.props.t("fundingType.prefundingrequest")})`}</div> : null}
                        </> 
                    )}
                    {datum_.fundingType == "PO" && (
                        <>
                            <span>{this.props.t("fundingType.po")}{datum_.isPreFundingRequest ? null : `(${datum_.fundingDocuments.length})`}</span>
                            {!datum_.isPreFundingRequest && (<>{isExpand ? <ExpandMore /> : <ExpandLess />}</>)}
                            {datum_.isPreFundingRequest ? <div className="progress-text-color">{`(${this.props.t("fundingType.prefundingrequest")})`}</div> : null}
                        </>
                        
                    )}
                    {datum_.fundingType == "ABL" && (
                        <span>{this.props.t("fundingType.abl")}</span>
                    )}
                    {datum_.fundingType == "SupplyChain" && (
                        <>
                            <span>{this.props.t("fundingType.supplychain")}{datum_.isPreFundingRequest ? null : `(${datum_.fundingDocuments.length})`}</span>
                            {!datum_.isPreFundingRequest && (<>{isExpand ? <ExpandMore /> : <ExpandLess />}</>)}
                            {datum_.isPreFundingRequest ? <div className="progress-text-color">{`(${this.props.t("fundingType.prefundingrequest")})`}</div> : null}
                        </>
                    )}
                    </div>;
                    
            },
            isExpandCell: true
        },
        {
            key: "createdAt",
            name: this.props.t("grid_view_header_labels.requested_date"),
            contentProvider: (datum_) => {
                return dateFormatter.format(new Date(datum_.createdAt));
            }
        },
        {
            key: "statusText",
            name: this.props.t("grid_view_header_labels.status"),
            contentProvider: (datum_,key_,isExpand) => {
                switch (datum_.status) {
                    case 0:
                        return <><span  className='cursor-pointer word-wrap'  style={{ color: "#4F4F4F", fontWeight: "600" }}>{datum_.statusText}</span>{isExpand?<ExpandMore/>:<ExpandLess/>}</>;
                    case 1:
                        return <><span className='cursor-pointer  word-wrap' style={{ color: "#FFB45B" }}>{datum_.statusText}</span>{isExpand?<ExpandMore/>:<ExpandLess/>}</>;
                    case 2:
                        return <><span  className='cursor-pointer word-wrap' style={{ color: "#46B2E3" }}>{datum_.statusText}</span>{isExpand?<ExpandMore/>:<ExpandLess/>}</>;
                    case 3:
                        return <><span  className='cursor-pointer word-wrap' style={{ color: "#FFB45B" }}>{datum_.statusText}</span>{isExpand?<ExpandMore/>:<ExpandLess/>}</>;
                    case 4:
                        return <><span  className='cursor-pointer word-wrap' style={{ color: "#4F984F" }}>{datum_.statusText}</span>{isExpand?<ExpandMore/>:<ExpandLess/>}</>;
                    case 5:
                        return <><span  className='cursor-pointer word-wrap' style={{ color: "#930000" }}>{datum_.statusText}</span>{isExpand?<ExpandMore/>:<ExpandLess/>}</>;
                    case 6:
                        return <>
                            <span className='cursor-pointer word-wrap' style={{ color: "#46B2E3" }}>
                                {datum_.statusText}
                            </span>{isExpand ? <ExpandMore /> : <ExpandLess />}
                            {(datum_.contracts && datum_.contracts.length && !datum_.contracts[0].fundingAggrement) ? <div style={{ color: "#46B2E3" }}>({this.props.t("waiting_for_funding_agreement")})</div>
                                : ((datum_.contracts && datum_.contracts.length && !datum_.contracts[0].signedAggrements) ? <div style={{ color: "#46B2E3" }}>({this.props.t("waiting_for_signed_funding_agreement")})</div> : "")}</>;
                    case 7:
                        return <><span  className='cursor-pointer word-wrap' style={{ color: "#4F984F" }}>{datum_.statusText}</span>{isExpand?<ExpandMore/>:<ExpandLess/>}</>;
                    case 8:
                        return <><span  className='cursor-pointer word-wrap' style={{ color: "#930000" }}>{datum_.statusText}</span>{isExpand ? <ExpandMore /> : <ExpandLess />}</>;
                    case 11:
                        return <><span  className='cursor-pointer word-wrap' style={{ color: "#46B2E3" }}>{datum_.statusText}</span>{isExpand?<ExpandMore/>:<ExpandLess/>}</>;
                    default:
                        return datum_.statusText;
                }
            },
            isExpandCell: true,
            staticWidthClass:"status-tablecell-width"
        },
        {
            key: "createdBy",
            name: "Created By",
            contentProvider: (datum_) => {
                return (
                    <>
                        {datum_.isCreatedByRepresentive ? (
                            <>
                                {datum_.createdUserDetails.name}
                            </>
                        ) : (
                            <>
                                {datum_.requesterDetails && datum_.requesterDetails.name ? datum_.requesterDetails.name : ""}
                            </>
                        )}
                    </>
                )
            }
        },
        {
            key: "contractId",
            name: this.props.t("grid_view_header_labels.active_contract"),
            contentProvider: (datum_) => {
                if (datum_.contractId) {
                    return (<div><NavLink className="anchorstyle" style={{ color: "#5F9FBC"}} to="/fundingcontract">{datum_.contractId}</NavLink>
                    {datum_.isUsingExistingContract && (<div style={{ color: "#930000", fontWeight: "600" }}>({this.props.t("additional_trading_partner_added")})</div>)}</div>);
                }

                return <i>{this.props.t("none")}</i>;
            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            allFundingRequests: [],
            openFundingReqInvoices: [],
            inFundingReqInvoices: [],
            archivedFundingReqInvoices: [],
            isViewPopupOpen: false,
            isMatchedFunders: false,
            selectedTCF: null,
            isViewDocuments: false,
            isViewMatchedFunders: false,
            isMakeOffer: false,
            isViewFundingOffers: false,
            isAcceptFundingReq: false,
            isDeclineFundingReq: false,
            isOpenMessageModal: false,
            isUploadFundingAgreement: false,
            isUploadSignedAgreement: false,
            isViewInvoiceModal: false,
            isViewPurcahseOrderModal: false,
            isViewSupplyChainModal:false,
            allfunders: [],
            selectedFundersMEG: [],
            filtersApplied: {},
            isResetFilterEnabled: false,
          //  isLoading: false,
            user: {},
            openFundingReqInvoicesSort: {
                sortBy: '',
                sort: ''
            },
            archivedFundingReqInvoicesSort: {
                sortBy: '',
                sort: ''
            },
            inFundingReqInvoicesSort: {
                sortBy: '',
                sort: ''
            },
            activeTab: "openInvoices",
            showPreFundingModal: false,
            steps: "",
            activeStep: '',
            statusCounts: [],
            fundingRequest: [],
            isUpdatePreFunding:false,
            allRequesters:[]
        };
    }

    componentDidMount() {
        document.title = `${this.user.currentUserRole() == "funder" ? "Funding Operations": "Funding Request"} - LedgerFunding ${this.user.currentUserRole()}`;
        if (compareRole(this.user.currentUserRole(), enumRole.Admin)) {
            this._columnsSpecification.unshift({
                key: "requesterName",
                name: "Requester Name",
                contentProvider: (datum_) => {
                    if (datum_.supplierName) {
                        return (<div style={{ color: "#255F79", fontWeight: 600 }}>
                            {datum_.supplierName} <span className="ml-2 badge badge-primary">
                                {compareRole(datum_.supplier.role ,enumRole.Supplier) ? enumRole.Supplier : enumRole.Buyer}
                            </span>
                        </div>);
                    }
                }
            })
        }
        this.getProfile();
    }
    componentWillUnmount() {
        this._isLive = false;
    }

    getProfile = async () => {
        let {activeTab}=this.state;
        const profileResponse = await this.api.getProfile();
        if(compareRole(this.user.currentUserRole(),enumRole.Funder) && this.props.match.params.tab){
            activeTab=this.props.match.params.tab;
        }
        this.setState({ user: profileResponse.user ,activeTab}, () => {
            this.getFundingRequest();
        });
    };

    updateUserProfile = async () => {
        const profileResponse = await this.api.getProfile();
        this.setState({ user: profileResponse.user});
    }

    async getFundingRequest() {
        // this.setState({ isLoading: true });
        const { user } = this.state;
        let fundingRequest=null;
        let _funderList=[];
        let _supplierList=[];
        let _isFunder=compareRole(this.user.currentUserRole(),enumRole.Funder);
        if(this.user.currentUserRole()=="admin"){
            fundingRequest = await this.api.getAllFundingRequest();
        }
        else{
            fundingRequest = await this.api.getFundingRequest();
        }
       
        if (fundingRequest == undefined) {
            return;
        }



        fundingRequest = fundingRequest.map(f_ => {
            f_.funderName = "";
            f_.supplierName="";
            const addrequesters=(data_)=>{
                if(_supplierList.length==0){
                        _supplierList.push(data_);
                }
                else{
                        let isExist = _supplierList.filter(x => x._id==data_._id).length>0;
                        if(!isExist){
                            _supplierList.push(data_);
                        }
                }
            }
            if(_isFunder){
                f_.supplierName=f_.requesterDetails && f_.requesterDetails.name?f_.requesterDetails.name:"";
                addrequesters(f_.requesterDetails);
            }
            else{
                f_.supplierName=f_.supplier && f_.supplier.name?f_.supplier.name:"";
                addrequesters(f_.supplier);
            }
            if (f_.contractId && f_.contracts && f_.contracts.length) {
                if (f_.contracts[0].funderDetails) {
                    f_.funderName = f_.contracts[0].funderDetails.name ? f_.contracts[0].funderDetails.name : "";
                    if(_funderList.length==0){
                        _funderList.push(f_.contracts[0].funderDetails);
                    }
                    else{
                        let isExist = _funderList.filter(x => x._id==f_.contracts[0].funderDetails._id).length>0;
                        if(!isExist){
                            _funderList.push(f_.contracts[0].funderDetails);
                        }
                    }
                }
            }
            if(f_.fundingDocuments.length>0){
                f_.fundingDocuments.map(d_=>{
                    d_.supplier=f_.supplier;
                    return d_;
                })
            }
            f_.isNoMoreLink=true;
            switch (f_.status) {
                case 0:
                    f_.statusText = this.props.t("status_label.drafted");
                    break;
                case 1:
                    f_.statusText = this.props.t("status_label.in_review");
                    break;
                case 2:
                    f_.statusText = this.props.t("status_label.funding_request_created");
                    break;
                case 3:
                    f_.statusText = this.props.t("status_label.awaiting_funding_offer");
                    break;
                case 4:
                    f_.statusText = compareRole(this.user.currentUserRole(),enumRole.Funder)?this.props.t("status_label.funding_offer_made"):this.props.t("status_label.funding_offer_received");
                    break;
                case 5:
                    f_.statusText = this.props.t("status_label.funding_declined");
                    break;
                case 6:
                    f_.statusText =f_.isSupplyChainFinancing?this.props.t("status_label.buyer_accepted_funding_offer"): this.props.t("status_label.supplier_accepted_funding_offer");
                    break;
                case 7:
                    f_.statusText = f_.isPreFundingRequest?f_.isSupplyChainFinancing?this.props.t("status_label.buyer_accepted_funding_offer"):this.props.t("status_label.pre_funded"): this.props.t("status_label.paid_back");
                    break;
                case 8:
                    f_.statusText = this.props.t("status_label.offer_not_accepted");
                    break;
                case 11:
                    f_.statusText = this.props.t("status_label.funding_request_approved_under_funding_contract");
                    break;
                default:
                    f_.statusText = this.props.t("status_label.unknown");
            }
            let isDeclinedFundingRequest = false;
            if (f_.declinedFunders && f_.declinedFunders.length > 0) {
                f_.declinedFunders.forEach(funder_ => {
                    if (user && (funder_ == user._id)) {
                        isDeclinedFundingRequest = true;
                    }
                });
            }
            f_.isDeclined = isDeclinedFundingRequest;
            return f_;
        });
        // Calculate the counts for each status up to 'statusUpto'
        let countsFor;
        this.user.currentUserRole() !== "funder" ? countsFor = 'FR' : countsFor = 'F_OPR';
        const statusUpto = 12; //Define the maximum status value to be calculated
        const counts = calculateStatusCounts(fundingRequest, statusUpto, countsFor);
        this.setState({ statusCounts: counts });

        if (this.user.currentUserRole() !="funder") {
            _funderList = _funderList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            this.setState({ allFundingRequests: fundingRequest,allfunders: _funderList  }, () => {
                this.onFilterChange();
            });
        }
        else {
            _supplierList = _supplierList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            fundingRequest=fundingRequest.sort((a,b)=>(a.status > b.status) ? 1 : -1);
            this.setState({allFundingRequests: fundingRequest,allRequesters: _supplierList },()=>{
                this.onFunderFilterChange();
            });
        }
        this._resetFilter()
    }

    openNotificationActionModal=()=>{
        const _notificationIds=NotificationService._id;
        if(_notificationIds && _notificationIds.length==1){
            if((this.state.openFundingReqInvoices && this.state.openFundingReqInvoices.length>0) || (this.state.allFundingRequests && this.state.allFundingRequests.length>0)){
                let _notificationFR=this.state.openFundingReqInvoices.filter(f_=>f_.fundingReqId==_notificationIds[0]);
                if(_notificationFR.length==0){
                    _notificationFR=this.state.allFundingRequests.filter(f_=>f_.fundingReqId==_notificationIds[0]);
                }
                NotificationService.emit(_notificationIds[0]);
                if (_notificationFR && _notificationFR.length > 0) {
                    const selectedTCF = _notificationFR[0];
                    const hasFundingAgreement = (selectedTCF.contracts && selectedTCF.contracts.length && selectedTCF.contracts[0].fundingAggrement) ? true : false;
                    const hasSignedAgreement = (selectedTCF.contracts && selectedTCF.contracts.length && selectedTCF.contracts[0].signedAggrements) ? true : false;
                    if(NotificationService._isMessageModal){
                        NotificationService._isMessageModal=false;
                        this.setState({
                            isOpenMessageModal:true,
                            selectedTCF:selectedTCF
                        })
                    }
                    else if(compareRole(this.user.currentUserRole(),enumRole.Funder)){
                        if(selectedTCF && selectedTCF.status==EnumFundingRequestStatus.FundingAccepted && !hasFundingAgreement){
                            this.setState({
                                isUploadFundingAgreement: true,
                                selectedTCF: selectedTCF,
                            });
                        }
                        else{
                            this.setState({
                                isViewPopupOpen: true,
                                selectedTCF: selectedTCF,
                            });
                        }
                    }
                    else{
                        if (selectedTCF && selectedTCF.status == 2) {
                            if (((compareRole(this.user.currentUserRole(), enumRole.Supplier) || compareRole(this.user.currentUserRole(), enumRole.Buyer)) && selectedTCF && selectedTCF.supplier && selectedTCF.supplier.isMatchedFunderByAdmin) ||
                                (selectedTCF.isCreatedByImpersonateUser)
                            ) {
                                AddModal(`${this.props.t("fundingrequests_page.match_funders_for")} # ${selectedTCF.fundingReqId}`, <div>
                                    {this.props.t("fundingrequests_page.please_contact")}  <b>admin@ledgerfunding.com</b> {this.props.t("fundingrequests_page.to_select_match_funder")}</div>, null, false);
                            }
                            else {
                                this.setState({
                                    isViewMatchedFunders: true,
                                    selectedTCF: selectedTCF,
                                });
                            }
                        }
                        else if(selectedTCF && selectedTCF.status == EnumFundingRequestStatus.FundingOffered){
                            this.setState({
                                isViewFundingOffers: true,
                                selectedTCF: selectedTCF,
                            });
                        }
                        else if(selectedTCF && selectedTCF.status == EnumFundingRequestStatus.FundingAccepted && 
                            selectedTCF.contracts && selectedTCF.contracts.length && selectedTCF.contracts[0].fundingAggrement && !selectedTCF.contracts[0].signedAggrements){
                            this.setState({
                                isUploadSignedAgreement: true,
                                selectedTCF: selectedTCF,
                            });
                        }
                        else if (selectedTCF && selectedTCF.status == EnumFundingRequestStatus.Repaid) {
                            this.state.filtersApplied.archived = true;
                            this.setState({
                                isViewDocuments: true,
                                selectedTCF: selectedTCF,
                                filtersApplied: this.state.filtersApplied
                            }, () => this.onFilterChange(), this._resetFilter());
                        }
                        else {
                            this.setState({
                                isViewDocuments: true,
                                selectedTCF: selectedTCF,
                            });
                        }
                    }
                }
            }
        }
    }

    handleCreatePreFundingRequest = (data) => {
        this.setState({ isLoading: true });

        // Just for Simulating...
        setTimeout(() => {
            this.setState({ isLoading: false, showPreFundingModal: false });
        }, 2000);
    }

    _expandRowItemsClick=(dataRow_, type_, index)=>{
        if (type_ == "viewInvoice") {
            this.setState({
                isViewInvoiceModal: true,
                selectedTCF: dataRow_,
            });
        }
        else if (type_ == "viewSupplyChain") {
            this.setState({
                isViewSupplyChainModal: true,
                selectedTCF: dataRow_,
            });
        }
        else if (type_ == "viewPurchaseOrder") {
            this.setState({
                isViewPurcahseOrderModal: true,
                selectedTCF: dataRow_,
            });
        }
    }

    _buildExpandRowItems = (row_, selectedItem_) => {
        return (
            <>
                {
                    selectedItem_ && selectedItem_.map(s_ => {
                            if (s_.type == "statusText") {
                                let _stepperItems = [
                                    {
                                        title: this.props.t("status_label.document_submitted"),
                                        icon: CheckmarkIcon
                                    },
                                    {
                                        title: this.props.t("status_label.requester_verification"),
                                        icon: CheckmarkIcon
                                    },
                                    {
                                        title: this.props.t("status_label.funding_request_created"),
                                        icon: CheckmarkIcon
                                    },
                                    {
                                        title: this.props.t("status_label.awaiting_funding_offers"),
                                        icon: CheckmarkIcon
                                    },
                                    {
                                        title: this.props.t("status_label.funding_offer_sent"),
                                        icon: row_.status == EnumFundingRequestStatus.FundingRejected ? CloseIcon : CheckmarkIcon
                                    },
                                    {
                                        title: row_.status == EnumFundingRequestStatus.RejectedFundingRequestOffer ? this.props.t("status_label.offer_not_accepted") : this.props.t("status_label.accept_funding_offer"),
                                        icon: row_.status == EnumFundingRequestStatus.RejectedFundingRequestOffer ? CloseIcon : CheckmarkIcon
                                    }

                                ];
                                if (!row_.isPreFundingRequest) {
                                    _stepperItems.push({
                                        title: this.props.t("status_label.paid_back"),
                                        icon: CheckmarkIcon
                                    });
                                }
                                return (<>
                                    <TableRow
                                        key={row_._id}
                                        className='buildItems-row'
                                    >
                                        <TableCell
                                            className="table-list-cell px-2 pt-2 pb-0 buildItems-cell"
                                            //style={{ padding: "none",backgroundColor:"#f0f8ff",borderBottom:"1px solid red" }}
                                            colSpan={12}
                                        >
                                            <Stepper
                                                circleFontSize={6}
                                                completeColor="#002f53"
                                                activeColor={(row_.status == EnumFundingRequestStatus.FundingRejected || EnumFundingRequestStatus.RejectedFundingRequestOffer) ? "#dc3545" : "#002f53"}
                                                defaultColor="#fff"
                                                defaultBorderColor="#e0e0e0"
                                                defaultBorderStyle="solid"
                                                defaultBorderWidth={1}
                                                steps={_stepperItems}
                                                activeStep={getStepForFRTimeline(row_.status)}

                                            />
                                        </TableCell>
                                    </TableRow>
                                </>)
                            }
                            else if (s_.type == "fundingDocuments" && !row_.isPreFundingRequest) {
                                let _headers = [];
                                if (compareTransactionType(row_.fundingType[0], transactionType.Invoice) || compareTransactionType(row_.fundingType[0], transactionType.SupplyChain)) {
                                    _headers.push(
                                        {
                                            key: "documentId",
                                            title: this.props.t("grid_view_header_labels.invoice_number"),
                                            eventType: compareTransactionType(row_.fundingType[0], transactionType.Invoice) ? "viewInvoice" : "viewSupplyChain"
                                        },
                                        {
                                            key: "total",
                                            title: this.props.t("grid_view_header_labels.invoice_amount"),
                                            textAlignment: "right",
                                            contentProvider: (datum_) => {
                                                return <div style={{ color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.total)}</div>;
                                            }
                                        },
                                        {
                                            key: "documentDueDate",
                                            title: this.props.t("grid_view_header_labels.due_date"),
                                            contentProvider: (datum_) => {
                                                return dateFormatter.format(new Date(datum_.documentDueDate));
                                            }
                                        },
                                        {
                                            key: "paymentTerm",
                                            title: this.props.t("invoice_labels.payment_term"),
                                            contentProvider: (datum_) => {
                                                
                                                return compareTransactionType(transactionType.Invoice, datum_.documentType)?addDaysInSufixForPaymentTerm(datum_.invoice.net):addDaysInSufixForPaymentTerm(datum_.supplyChain.net)
                                                
                                            }
                                        }
                                    )
                                }
                                else {
                                    _headers.push(
                                        {
                                            key: "documentId",
                                            title: this.props.t("grid_view_header_labels.po_number"),
                                            eventType: "viewPurchaseOrder"
                                        },
                                        {
                                            key: "total",
                                            title: this.props.t("grid_view_header_labels.po_amount"),
                                            textAlignment: "right",
                                            contentProvider: (datum_) => {
                                                return <div style={{ color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.total)}</div>;
                                            }
                                        },
                                        {
                                            key: "createdAt",
                                            title: this.props.t("grid_view_header_labels.created_at"),
                                            contentProvider: (datum_) => {
                                                return dateFormatter.format(new Date(datum_.createdAt));
                                            }
                                        }
                                    );
                                }
                                return (
                                    <TableRow
                                        key={row_._id}
                                        className='buildItems-row'
                                    >
                                        <TableCell
                                            className="table-list-cell px-2 pt-2 pb-0 buildItems-cell"
                                            //style={{ padding: "none",backgroundColor:"#f0f8ff",borderBottom:"1px solid e0e0e0" }}
                                            colSpan={12}
                                            align='center'
                                        >
                                            <div></div>
                                            <div className='row mx-0'>
                                                {_headers.map((h_, i) => {
                                                    return (
                                                        <div
                                                            className='col-3'
                                                            style={{ textTransform: "uppercase", color: "#255F79" }}
                                                        >
                                                            {h_.title}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {row_.fundingDocuments && row_.fundingDocuments.length > 0 && row_.fundingDocuments.map((data_, i) => {
                                                return (
                                                    <div className='row mx-0'>
                                                        {_headers.map((h_, i) => {
                                                            return (
                                                                <div className={h_.eventType ? "data-grid-document cursor-pointer col-3" : "col-3"} onClick={(e) => {
                                                                    if (h_.eventType) {
                                                                        this._expandRowItemsClick(data_, h_.eventType, i);
                                                                    }
                                                                }}>
                                                                    {h_.contentProvider ? h_.contentProvider(data_) : data_[h_.key]}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        
                    })
                }
            </>
        )
    }

    handleStepClick = (step, status) => {
        const isSupplier = compareRole(this.user.currentUserRole(), enumRole.Supplier);
        const isBuyer = compareRole(this.user.currentUserRole(), enumRole.Buyer);
        this.setState(prevState => {
            const isSameStep = prevState.activeStep === step;

            let archived;
            if (isSupplier || isBuyer) {
                archived = status == 7 || status == 5 ? true : false;
            } else {
                archived = status == 79 ? true : false;
            }
            return {
                activeStep: isSameStep ? "" : step,
                filtersApplied: isSameStep
                    ? {
                        ...prevState.filtersApplied,
                        textFilter: "",
                        archived: false,
                        funders: [],
                        status: []
                    }
                    : {
                        ...prevState.filtersApplied,
                        status: [{ value: status }],
                        archived: archived,
                    }
            };
        }, () => {
            if(compareRole(this.user.currentUserRole(),enumRole.Funder)){
                this.onFunderFilterChange(status);
            }
            else{
                this.onFilterChange(status);
            }
        });
    };

    generateStepData = () => {
        const { statusCounts } = this.state;
        let stepTitles;
        let statusToInclude;
        let statuRequiredAction;
        let statusRequiredDropdown;
        let dropdownItems;

        if (this.user.currentUserRole() !== "funder") {
            stepTitles = [
                this.props.t("stepper_labels.fun_req.awaiting_submission"),
                this.props.t("stepper_labels.fun_req.in_review"),
                this.props.t("stepper_labels.fun_req.funding_request"),
                this.props.t("stepper_labels.fun_req.awaiting_funding_offer"),
                this.props.t("stepper_labels.fun_req.offer_received"),
                this.props.t("stepper_labels.fun_req.awaiting_funder_agreement"),
                this.props.t("stepper_labels.fun_req.awaiting_countersign"),
                this.props.t("stepper_labels.fun_req.funding_processed"),
                this.props.t("stepper_labels.fun_req.archived"),

            ];
            dropdownItems = {
                63: [
                    { label: this.props.t("stepper_labels.fun_req.drop_down.accept_offer"), countKey: '64' },
                    { label: this.props.t("stepper_labels.fun_req.drop_down.funding_approved"), countKey: '11' },
                ],
                7: [
                    { label: this.props.t("stepper_labels.fun_req.drop_down.paid_back"), countKey: '77' },
                    { label: this.props.t("stepper_labels.fun_req.drop_down.pre_funded"), countKey: '78' },
                    { label: this.props.t("stepper_labels.fun_req.drop_down.funding_declined"), countKey: '5' }
                ]
            };
            statusToInclude = [0, 1, 2, 3, 4, 61, 62, 63, 7];
            statusRequiredDropdown = [63, 7];
            statuRequiredAction = [0, 2, 4, 62];
        } else {
            stepTitles = [
                this.props.t("stepper_labels.fun_opr.awaiting_funding_offer"),
                this.props.t("stepper_labels.fun_opr.funding_offer_made"),
                this.props.t("stepper_labels.fun_opr.awaiting_funder_agreement"),
                this.props.t("stepper_labels.fun_opr.awaiting_signed_agreement"),
                this.props.t("stepper_labels.fun_opr.awaiting_payback"),
                this.props.t("stepper_labels.fun_opr.archived"),
            ];
            dropdownItems = {
                79: [
                    { label: this.props.t("stepper_labels.fun_opr.drop_down.paid_back"), countKey: '77' },
                    { label: this.props.t("stepper_labels.fun_opr.drop_down.funding_declined"), countKey: '5' },
                    { label: this.props.t("stepper_labels.fun_opr.drop_down.pre_funded"), countKey: '78' },
                    { label: this.props.t("stepper_labels.fun_opr.drop_down.offer_not_accepted"), countKey: '8' }
                ],
                63: [
                    { label: this.props.t("stepper_labels.fun_opr.drop_down.accepted_offer"), countKey: '64' },
                    { label: this.props.t("stepper_labels.fun_opr.drop_down.funding_approved"), countKey: '11' },
                    
                ]
            };
            statusToInclude = [3, 4, 61, 62, 63, 79];
            statusRequiredDropdown = [63, 79];
            statuRequiredAction = [3, 61, 63,64];
        }
        // Status to show dropdown
        return generateSteps(statusCounts, stepTitles, statusToInclude, statuRequiredAction, statusRequiredDropdown, dropdownItems);
    }


    render() {

        const {
            openFundingReqInvoices,
            isLoading,
            user,
            showPreFundingModal,
            activeStep
        } = this.state;
        const financingVolume = (user?.questionnaires?.[0]?.questionnaire?.estimatedFinancingVolume ?? '').replace('$', '');
        const steps = this.generateStepData();
        return (

            <AppProvider>
                <AppState.Consumer>
                    {appState => (
                        <React.Fragment>
                            <FullSpinner isSpinning={isLoading} />
                            <div className="header-titlebar">
                                <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{compareRole(this.user.currentUserRole(), enumRole.Funder) ? this.props.t("page_titles.fundingoperations") : this.props.t("page_titles.fundingrequests")}</div>
                                    {compareRole(this.user.currentUserRole(), enumRole.Supplier) && (
                                        <div>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    // const userDetails = this.state.user;
                                                    // const isQuesDetails = userDetails.questionnaires && userDetails.questionnaires.length > 0 ? true : false;
                                                    // if((isUSCountryUser(userDetails.country) && !userDetails.taxId)){
                                                    //     AddModal('Incomplete financial information',  <div>
                                                    //     Please enter <b>Tax ID</b> in   <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink></div> , null, false);
                                                    // }
                                                    // else{
                                                    //     if(isUSCountryUser(userDetails.country)){
                                                    //         const _taxID=userDetails.taxId?userDetails.taxId.replace(/[^0-9.]/g, ""):"";
                                                    //         if(_taxID.length==9){
                                                    //             this.setState({ showPreFundingModal: true });
                                                    //         }
                                                    //         else{
                                                    //             AddModal('Incomplete financial information',  <div>
                                                    //             Please update <b>Tax ID</b> in   <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink></div> , null, false);
                                                    //         }

                                                    //     }
                                                    //     else {
                                                    //         this.setState({ showPreFundingModal: true });
                                                    //     }
                                                    // }
                                                    this.setState({ showPreFundingModal: true });
                                                }}
                                            >
                                                {this.props.t("fundingrequests_page.pre_funding_request_content.pre_funding_request")}
                                            </button>
                                        </div>
                                    )}

                                </h3>
                            </div>

                            <div className="content-container fundingrequest-list">
                                <div>
                                    {this.state.isViewPopupOpen && this.state.selectedTCF && <ViewFudingRequestModal
                                        isReadOnly={true}
                                        t={this.props.t}
                                        onClose={() => { this.setState({ isViewPopupOpen: false, selectedTCF: null }); }}
                                        data={this.state.selectedTCF}
                                    />}
                                    {this.state.isViewMatchedFunders && this.state.selectedTCF && <ViewMatchedFunderModal
                                    t={this.props.t}
                                        onClose={() => {
                                            this.setState({ isViewMatchedFunders: false, selectedTCF: null });
                                            this.getFundingRequest();
                                        }}
                                        data={this.state.selectedTCF}
                                    />}

                                    {this.state.isViewDocuments && this.state.selectedTCF && <ViewSupplierFundingRequestModal
                                    t={this.props.t}
                                        isReadOnly={true}
                                        onClose={() => { this.setState({ isViewDocuments: false, selectedTCF: null }); }}
                                        data={this.state.selectedTCF}
                                    />}

                                    {this.state.isMakeOffer && this.state.selectedTCF && <MakeOfferModal
                                    t={this.props.t}
                                        isLoading={false}
                                        onClose={() => {
                                            this.setState({ isMakeOffer: false, selectedTCF: null });
                                            this.getFundingRequest();
                                        }}
                                        data={this.state.selectedTCF}
                                    />}
                                    {this.state.isViewFundingOffers && this.state.selectedTCF && <ViewFundingOfferModal
                                    t={this.props.t}
                                        isLoading={false}
                                        onClose={() => {
                                            this.setState({ isViewFundingOffers: false, selectedTCF: null });
                                            this.getFundingRequest();
                                        }}
                                        data={this.state.selectedTCF}
                                    />}
                                    {this.state.isAcceptFundingReq && this.state.selectedTCF && (
                                        <ApproveFundingRequestModal
                                        t={this.props.t}
                                            data={this.state.selectedTCF}
                                            onClose={() => {
                                                this.setState({ isAcceptFundingReq: false, selectedTCF: null });
                                                this.getFundingRequest();
                                            }}
                                        />
                                    )}
                                    {/* {
                                        this.state.isAcceptFundingReq && (
                                            <CommonConfirmationModal
                                                title="Approve Funding Request"
                                                submitText="Approve Request"
                                                onClose={() => {
                                                    this.setState({ isAcceptFundingReq: false, selectedTCF: null });
                                                    this.getFundingRequest();
                                                }}
                                                onSubmit={() => {
                                                    return this.api.acceptFundingRequest(this.state.selectedTCF._id).then(r => {
                                                        this.getFundingRequest();
                                                    });
                                                }}
                                            >
                                                Are you sure you want to approve this funding Request?
                                            </CommonConfirmationModal>

                                        )
                                    } */}
                                    {
                                        this.state.isDeclineFundingReq && (
                                            <CommonConfirmationModal
                                                title={this.props.t("decline_funding_request_title")}
                                                submitText={this.props.t("button_names.decline")}
                                                onClose={() => {
                                                    this.setState({ isDeclineFundingReq: false, selectedTCF: null });
                                                    this.getFundingRequest();
                                                }}
                                                onSubmit={() => {
                                                    return this.api.declineFundingRequest(this.state.selectedTCF._id).then(r => {
                                                        this.getFundingRequest();
                                                    });
                                                }}
                                                isPrimaryButtonDanger={true}
                                            >
                                                {this.props.t("decline_funding_request_msg")}
                                            </CommonConfirmationModal>

                                        )
                                    }
                                    {
                                        this.state.isShowPaidModal && (
                                            <CommonConfirmationModal
                                                title={this.props.t("mark_as_paid_title")}
                                                submitText={this.props.t("button_names.mark_as_paid")}
                                                id="confirm-markas-paid"
                                                onClose={() => {
                                                    this.setState({ isShowPaidModal: false, selectedTCF: null });
                                                    this.getFundingRequest();
                                                }}
                                                onSubmit={() => {
                                                    return this.api.markasPaidFundingRequest(this.state.selectedTCF.fundingReqId).then(r => {
                                                        this.getFundingRequest();
                                                    });
                                                }}

                                            >
                                                {this.props.t("mark_as_paid_msg_1")} <b>{this.props.t("mark_as_paid_msg_2")}</b> {this.props.t("mark_as_paid_msg_3")}
                                            </CommonConfirmationModal>

                                        )
                                    }
                                    {
                                        (this.state.isOpenMessageModal && this.state.selectedTCF) &&
                                        <MessageModal
                                            t={this.props.t}
                                            messageType=""
                                            title={
                                                compareRole(this.user.currentUserRole(),enumRole.Funder)?
                                                 `${this.props.t("message_requsester")} (${this.state.selectedTCF.requesterDetails.name}) for ${this.state.selectedTCF.fundingReqId}`:
                                                 <div className="title"><div className="mini-title">{this.props.t("message_funder")}</div><div className="large-title">#{this.state.selectedTCF.fundingReqId}</div></div>
                                            }
                                            //recipients = {[this.state.selectedTCF.selectedFunders]}
                                            recipients={
                                                (appState.state.currentUserRole == "supplier" || appState.state.currentUserRole == "buyer") ? this.state.selectedTCF.selectedFunders : [this.state.selectedTCF.requesterDetails]}
                                            item={this.state.selectedTCF}
                                            userRole={appState.state.currentUserRole}
                                            onClose={() => {
                                                this.setState({
                                                    isOpenMessageModal: false,
                                                    selectedTCF: null
                                                });
                                            }} />
                                    }
                                    {
                                        (this.state.isUploadFundingAgreement && this.state.selectedTCF) &&
                                        <FundingAgreementModal
                                            t={this.props.t}
                                            data={this.state.selectedTCF}
                                            onClose={() => {
                                                this.setState({
                                                    isUploadFundingAgreement: false,
                                                    selectedTCF: null
                                                }, () => {
                                                    this.getFundingRequest();
                                                });
                                            }}
                                        />
                                    }
                                    {
                                        (this.state.isUploadSignedAgreement && this.state.selectedTCF) &&
                                        <SignedAgreementModal
                                            t={this.props.t}
                                            data={this.state.selectedTCF}
                                            onClose={() => {
                                                this.setState({
                                                    isUploadSignedAgreement: false,
                                                    selectedTCF: null
                                                }, () => {
                                                    this.getFundingRequest();
                                                });
                                            }}
                                        />
                                    }
                                    {this.state.isViewPurcahseOrderModal && this.state.selectedTCF && <POModal
                                       t={this.props.t}
                                        isLoading={false}
                                        isReadOnly={true}
                                        onClose={() => {
                                            this.setState({ isViewPurcahseOrderModal: false, selectedTCF: null });
                                        }}
                                        data={this.state.selectedTCF}
                                    />}
                                    {this.state.isViewInvoiceModal && this.state.selectedTCF && <ViewInvoiceModal
                                        isLoading={false}
                                        t={this.props.t}
                                        onClose={() => {
                                            this.setState({ isViewInvoiceModal: false, selectedTCF: null });
                                            this.getFundingRequest();
                                        }}
                                        data={this.state.selectedTCF}
                                    />}
                                    {this.state.isViewSupplyChainModal && this.state.selectedTCF && <UploadSupplyChainModal
                                        isReadOnly={true}
                                        t={this.props.t}
                                        isLoading={false}
                                        onClose={() => {
                                            this.setState({ isViewSupplyChainModal: false, selectedTCF: null });
                                            this.getFundingRequest();
                                        }}
                                        data={this.state.selectedTCF}
                                    />}
                                </div>
                                {(appState.state.currentUserRole !== enumRole.Funder) && (
                                    <StatusStepper
                                        activeStep={activeStep}
                                        steps={steps}
                                        statusCounts={this.state.statusCounts}
                                        handleStepClick={this.handleStepClick}
                                    />
                                )}
                                {(appState.state.currentUserRole !== enumRole.Funder) && (
                                    <DataGrid
                                        title="Funding Requests"
                                        data={openFundingReqInvoices}
                                        columns={this._columnsSpecification}
                                        isNoPagination={true}
                                        isRowsNonSelectable={true}
                                        isNoMoreLink={false}
                                        isGridSearch={true}
                                        popoverItems={[
                                            { key: "View", label: this.props.t("grid_view_action_labels.view_transactions"), role: appState.state.currentUserRole },
                                            { key: "ViewFunders", label: this.props.t("grid_view_action_labels.view_matched_funders"), role: appState.state.currentUserRole },
                                            { key: "Message", label: this.props.t("grid_view_action_labels.message_funder"), role: appState.state.currentUserRole },
                                            { key: "ViewFundersOffer", label: this.props.t("grid_view_action_labels.view_funding_offer"), role: appState.state.currentUserRole },
                                            { key: "SignAgreement", label: this.props.t("grid_view_action_labels.view_sign_aggreement"), role: appState.state.currentUserRole },
                                            { key: "PreFunding", label: this.props.t("grid_view_action_labels.continue_pre_funding"), role: appState.state.currentUserRole },
                                            { key: "editPreFunding", label: this.props.t("grid_view_action_labels.update_pre_funding_request"), role: appState.state.currentUserRole }
                                        ]}
                                        onPopoverClick={(key_, row_) => {
                                            if (key_ == "ViewFunders") {
                                                if((!compareRole(this.user.currentUserRole(),enumRole.Admin)) && row_ && ((row_.supplier && row_.supplier.isMatchedFunderByAdmin)|| (row_.isCreatedByImpersonateUser)) ){
                                                    AddModal("Matched Funders for #" + row_.fundingReqId, <div>
                                                    {this.props.t("fundingrequests_page.please_contact")} <b>admin@ledgerfunding.com</b> {this.props.t("fundingrequests_page.to_select_match_funder")}</div> , null, false);
                                                }
                                                else{
                                                    this.setState({
                                                        isViewMatchedFunders: true,
                                                        selectedTCF: row_,
                                                    });
                                                }
                                            }
                                            else if (key_ == "PreFunding" ||key_=="editPreFunding") {
                                                this.setState({
                                                    showPreFundingModal: true,
                                                    selectedTCF: row_,
                                                    isUpdatePreFunding:key_=="editPreFunding"
                                                });
                                            }
                                            else if (key_ == "View") {
                                                this.setState({
                                                    isViewDocuments: true,
                                                    selectedTCF: row_,
                                                });
                                            }
                                            else if (key_ == "ViewFundersOffer") {
                                                this.setState({
                                                    isViewFundingOffers: true,
                                                    selectedTCF: row_,
                                                });
                                            }
                                            else if (key_ == "Message") {
                                                this.setState({
                                                    isOpenMessageModal: true,
                                                    selectedTCF: row_,
                                                });
                                            }
                                            else if (key_ == "SignAgreement") {
                                                this.setState({
                                                    isUploadSignedAgreement: true,
                                                    selectedTCF: row_,
                                                });
                                            }
                                        }}
                                        appliedFilter={this.state.filtersApplied}
                                        resetFilters={() => this._resetFilter()}
                                        searchText={(text_) => {
                                            this.state.filtersApplied.textFilter = text_;
                                            this.setState({
                                                filtersApplied: this.state.filtersApplied
                                            }, () => this.onFilterChange());
                                        }}
                                        defaultSortColumnKey={"createdAt"}
                                        defaultSortDirection={"desc"}
                                        filterComponent={
                                            <React.Fragment>
                                                <DataGridFilter
                                                    label={this.props.t("grid_view_filter_names.funder")}
                                                    type="options"
                                                    options={this.state.allfunders.map(f => { return { text: f.name, value: f.name }; })}
                                                    value={this.state.filtersApplied.funders}
                                                    change={(filter_) => {
                                                        this.state.filtersApplied.funders = filter_;
                                                        this.setState({
                                                            filtersApplied: this.state.filtersApplied
                                                        }, () => this.onFilterChange());
                                                    }}
                                                />
                                            </React.Fragment>
                                        }
                                        isResetFilterEnabled={this.state.isResetFilterEnabled}
                                        emptydataLabel={this.props.t("no_funding_request_found")}
                                    />

                                )}
                                {showPreFundingModal  && appState.state.currentUserRole !== enumRole.Funder && (
                                    <PreFundingRequestModal
                                        userDetails={user}
                                        isPreFundingModalOpen={showPreFundingModal}
                                        financingVolume={financingVolume}
                                        data={this.state.selectedTCF}
                                        onClose={() => {
                                            this.setState({ showPreFundingModal: false ,selectedTCF:null,isUpdatePreFunding:false},()=>{
                                                this.getFundingRequest();
                                                this.updateUserProfile();
                                            });
                                        }}
                                        t={this.props.t}
                                        isUpdate={this.state.isUpdatePreFunding}
                                    />
                                )}
                                {compareRole(this.user.currentUserRole(), enumRole.Funder) && (
                                    <>
                                        <StatusStepper
                                            activeStep={activeStep}
                                            steps={steps}
                                            statusCounts={this.state.statusCounts}
                                            handleStepClick={this.handleStepClick}
                                        />
                                        <DataGrid
                                            title="Funding Operations"
                                            data={openFundingReqInvoices}
                                            columns={this._funderColumnSpecification}
                                            isNoPagination={true}
                                            isRowsNonSelectable={true}
                                            isNoMoreLink={false}
                                            isGridSearch={true}
                                            defaultSortColumnKey={"createdAt"}
                                            defaultSortDirection={"desc"}
                                            popoverItems={[
                                                { key: "viewFundingRequest", label: this.props.t("grid_view_action_labels.view_funding_request") },
                                                { key: "Message", label: this.props.t("grid_view_action_labels.message_requester") },
                                                { key: "makeOffer", label: this.props.t("grid_view_action_labels.make_offer") },
                                                { key: "acceptReq", label: this.props.t("grid_view_action_labels.accept_request") },
                                                { key: "declineReq", label: this.props.t("grid_view_action_labels.decline_request") },
                                                { key: "fundingAgreement", label: this.props.t("grid_view_action_labels.upload_funding_agreement") },
                                                { key: "markAsPaid", label: this.props.t("grid_view_action_labels.mark_as_paid") },
                                            ]}
                                            onPopoverClick={(key_, row_) => {
                                                if (key_ == "viewFundingRequest") {
                                                    this.setState({
                                                        isViewPopupOpen: true,
                                                        selectedTCF: row_,
                                                    });
                                                }
                                                else if (key_ == "Message") {
                                                    this.setState({
                                                        isOpenMessageModal: true,
                                                        selectedTCF: row_,
                                                    });
                                                }
                                                else if (key_ == "makeOffer") {
                                                    this.setState({
                                                        isMakeOffer: true,
                                                        selectedTCF: row_,
                                                    });
                                                }
                                                else if (key_ == "acceptReq") {
                                                    this.setState({
                                                        isAcceptFundingReq: true,
                                                        selectedTCF: row_,
                                                    });
                                                }
                                                else if (key_ == "declineReq") {
                                                    this.setState({
                                                        isDeclineFundingReq: true,
                                                        selectedTCF: row_,
                                                    });
                                                }
                                                else if (key_ == "fundingAgreement") {
                                                    this.setState({
                                                        isUploadFundingAgreement: true,
                                                        selectedTCF: row_,
                                                    });
                                                }
                                                else if (key_ == "markAsPaid") {
                                                    this.setState({
                                                        isShowPaidModal: true,
                                                        selectedTCF: row_,
                                                    });
                                                }
                                            }}
                                            appliedFilter={this.state.filtersApplied}
                                            resetFilters={() => this._resetFilter()}
                                            searchText={(text_) => {
                                                this.state.filtersApplied.textFilter = text_;
                                                this.setState({
                                                    filtersApplied: this.state.filtersApplied
                                                }, () => {
                                                    this.onFunderFilterChange();
                                                });
                                            }}
                                            handleTableCellClick={(dataRow_, type_, index) => {
                                                if (type_ == "view") {
                                                    this.setState({
                                                        isViewPopupOpen: true,
                                                        selectedTCF: dataRow_,
                                                    });
                                                }
                                                else if (type_ == "viewInvoice") {
                                                    this.setState({
                                                        isViewInvoiceModal: true,
                                                        selectedTCF: dataRow_,
                                                    });
                                                }
                                                else if (type_ == "viewSupplyChain") {
                                                    this.setState({
                                                        isViewSupplyChainModal: true,
                                                        selectedTCF: dataRow_,
                                                    });
                                                }
                                                else if (type_ == "viewPurchaseOrder") {
                                                    this.setState({
                                                        isViewPurcahseOrderModal: true,
                                                        selectedTCF: dataRow_,
                                                    });
                                                }
                                            }}
                                            filterComponent={
                                                <React.Fragment>
                                                    <DataGridFilter
                                                        label={this.props.t("requester_label")}
                                                        type="options"
                                                        options={this.state.allRequesters.map(f => { return { text: f.name, value: f.name }; })}
                                                        value={this.state.filtersApplied.allRequesters}
                                                        change={(filter_) => {
                                                            this.state.filtersApplied.allRequesters = filter_;
                                                            this.setState({
                                                                filtersApplied: this.state.filtersApplied
                                                            }, () => this.onFunderFilterChange());
                                                        }}
                                                    />
                                                </React.Fragment>
                                            }
                                            isResetFilterEnabled={this.state.isResetFilterEnabled}
                                            emptydataLabel={this.props.t("no_funding_request_found")}
                                            buildExpandRowItems={(row_,selectedItem_)=>this._buildExpandRowItems(row_,selectedItem_)}
                                        />
                                    </>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </AppState.Consumer>
            </AppProvider>
        );
    }

    _resetFilter = () => {
        this.state.filtersApplied.textFilter = "";
        this.state.filtersApplied.archived = false;
        this.state.filtersApplied.funders = [];
        this.state.filtersApplied.status = [];
        this.state.filtersApplied.allRequesters = [];
        this.setState({
            filtersApplied: this.state.filtersApplied,
            activeStep: '',
        }, () => {
            if(compareRole(this.user.currentUserRole(),enumRole.Funder)){
                this.onFunderFilterChange();
            }
            else{
                this.onFilterChange();
            }
        });
    };

    _hasResetFilterEnabled = () => {
        if ((this.state.filtersApplied.textFilter && this.state.filtersApplied.textFilter != "") || this.state.filtersApplied.archived || (this.state.filtersApplied.funders && this.state.filtersApplied.funders.length > 0)
            || (this.state.filtersApplied.status && this.state.filtersApplied.status.length > 0)  || (this.state.filtersApplied.allRequesters && this.state.filtersApplied.allRequesters.length > 0)) {
            this.setState({ isResetFilterEnabled: true });
        }
        else {
            this.setState({ isResetFilterEnabled: false });
        }
    };

    onFilterChange(status) {
        const {filtersApplied}=this.state;
        let filteredText = this.state.filtersApplied.textFilter;
        if (status === undefined && this.state.filtersApplied.status?.length) {
            status = this.state.filtersApplied.status[0].value;
        }
        if (filteredText) {
            filteredText = filteredText.toLowerCase();
        }

        let filteredFRs = this.state.allFundingRequests.filter(fr_ => {
            if (!this.state.filtersApplied.archived || status == 5) {
                if (fr_.status == 7 || fr_.status == 5) {
                    return false;
                }
            }
            if (filtersApplied.funders && filtersApplied.funders.length) {
                if (fr_.funderName != filtersApplied.funders[0].text) {
                    return false;
                }
            }
            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (fr_.status === 6 && (status === 61 || status === 62 || status === 63 || filtersApplied.status[0].text==62)) {
                    if (fr_.contracts && fr_.contracts.length && !fr_.contracts[0].fundingAggrement) {
                        return this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 61);
                    } else if (fr_.contracts && fr_.contracts.length && !fr_.contracts[0].signedAggrements) {
                        return this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 62);
                    } else if (this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 64)) {
                        if (![64, 11].includes(fr_.status)) {
                            return false;
                        }
                    }
                }
                else if (this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 63)) {
                    if (![64, 11].includes(fr_.status)) {
                        return false;
                    }
                }
            }
            if (status !== 63 && status !== 7 && this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (this.user.currentUserRole() == "funder" && status == 3 && fr_.isDeclined) {
                    return false;
                }
                else if (status != 79 && fr_.status != this.state.filtersApplied.status[0].value) {
                    return false;
                } else if (status == 79 && fr_.status != 7 && fr_.status != 5 && fr_.status != 8) {
                    return false;
                }
            }

            if (status == 7 && this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (fr_.status != this.state.filtersApplied.status[0].value) {
                    if (![5, 7].includes(fr_.status)) {
                        return false;
                    }
                }
            }

            if (filteredText) {
                if (!(fr_.funderName.toLowerCase().indexOf(filteredText) >= 0 ||
                    fr_.fundingReqId.toLowerCase().indexOf(filteredText) >= 0 ||
                    fr_.statusText.toLowerCase().indexOf(filteredText) >= 0)) {
                    return false;
                }
            }

            return true;
        });
        this.setState({
            openFundingReqInvoices: filteredFRs
        }, () => {
            this._hasResetFilterEnabled();
            this.openNotificationActionModal();
        });
    }

    onFunderFilterChange(status) {
        let filteredText = this.state.filtersApplied.textFilter;

        if (filteredText) {
            filteredText = filteredText.toLowerCase();
        }

        if (status === undefined && this.state.filtersApplied.status?.length) {
            status = this.state.filtersApplied.status[0].value;
        }
        

        let filteredFRs = this.state.allFundingRequests.filter(fr_ => {
            if (!this.state.filtersApplied.archived) {
                if (fr_.status == 5 || fr_.status == 8 || fr_.status == 7) {
                    return false;
                }
            }
            if (this.state.filtersApplied.allRequesters && this.state.filtersApplied.allRequesters.length) {
                if (fr_.supplierName != this.state.filtersApplied.allRequesters[0].text) {
                    return false;
                }
            }
            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (fr_.status === 6 && (status === 61 || status === 62 || status === 63)) {
                    if (fr_.contracts && fr_.contracts.length && !fr_.contracts[0].fundingAggrement) {
                        return this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 61);
                    } else if (fr_.contracts && fr_.contracts.length && !fr_.contracts[0].signedAggrements) {
                        return this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 62);
                    } 
                } else if (this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 63)) {
                    if (![64, 11].includes(fr_.status)) {
                        return false;
                    }
                }

            }

            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                // if (this.user.currentUserRole() == "funder" && status == 3 && fr_.isDeclined) {
                //     return false;
                // }
                if (status != 79 && status != 63 && fr_.status != this.state.filtersApplied.status[0].value) {
                    return false;
                } else if (status == 79 && fr_.status != 7 && fr_.status != 5 && fr_.status != 8) {
                    return false;
                }
            }

            if (filteredText) {
                if (!(fr_.supplierName.toLowerCase().indexOf(filteredText) >= 0 ||
                    fr_.fundingReqId.toLowerCase().indexOf(filteredText) >= 0 )) {
                    return false;
                }
            }

            return true;
        });

        this.setState({
            openFundingReqInvoices: filteredFRs
        }, () => {
            this._hasResetFilterEnabled();
            this.openNotificationActionModal();
        });
    }
}

export default compose(withRouter,withTranslation())(FundingRequest);